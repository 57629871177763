import { makeStyles } from '@material-ui/core';

export const styles = theme => ({
  assetItemContainer: {},
  assetItemName: {
    fontFamily: 'Roboto-Bold',
    fontSize: 14,
    color: '#4D4F5C',
    margin: 0
  },
  assetItemTotalValue: {
    fontFamily: 'Roboto-Regular',
    fontSize: 14,
    color: '#4D4F5C',
    margin: 0,
    [theme.breakpoints.down('sm')]: {
      marginBottom: 15,
    }
  },
  progressRoot: {
    width: '60%',
    height: 32,
    backgroundColor: 'transparent',
    borderRadius: 3
  },
  progressBar: {
    backgroundColor: `${theme.palette.primary.main}40`,  // 40 is hex presentation of alpha 0.25
    borderRadius: 3
  },
  navigationPanel: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  assetItemActionButton: {
    textTransform: 'none',
    color: theme.palette.primary.main,
    fontFamily: 'Roboto-Medium',
    fontSize: 14,
    display: 'flex',
    alignItems: 'center',

    '& i': {
      marginRight: 5
    },
    marginLeft: 5
  },
  assetItemActionButtonOutlined: {
    border: `2px solid ${theme.palette.primary.main}`,
    borderRadius: 2,

    '&:hover': {
      border: `2px solid ${theme.palette.primary.main}`,
    }
  }
});

export default makeStyles(styles);