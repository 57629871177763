import React from 'react'
import {ReactComponent as cash_icon} from "../../images/RiskProfiling/assets_icons/cash.svg";
import {ReactComponent as commodities} from "../../images/RiskProfiling/assets_icons/commodities.svg";
import {ReactComponent as company_shares} from "../../images/RiskProfiling/assets_icons/company_shares.svg";
import {ReactComponent as estates} from "../../images/RiskProfiling/assets_icons/estates.svg";
import {ReactComponent as real_estate} from "../../images/RiskProfiling/assets_icons/real_estate.svg";
import {ReactComponent as life_insurance} from "../../images/RiskProfiling/assets_icons/life_insurance.svg";
import {ReactComponent as private_company} from "../../images/RiskProfiling/assets_icons/private_company.svg";
import {ReactComponent as receivable_loans} from "../../images/RiskProfiling/assets_icons/receivable_loans.svg";
import {ReactComponent as real_estate_2} from "../../images/RiskProfiling/assets_icons/real_estate_2.svg";
import {ReactComponent as savings} from "../../images/RiskProfiling/assets_icons/savings.svg";
import {ReactComponent as gold} from "../../images/RiskProfiling/assets_icons/gold.svg";
import {ReactComponent as jewellery} from "../../images/RiskProfiling/assets_icons/jewellery.svg";
import {ReactComponent as valuable_items} from "../../images/RiskProfiling/assets_icons/valuable_items.svg";
import {ReactComponent as antiques} from "../../images/RiskProfiling/assets_icons/antiques.svg";
import {ReactComponent as other_collectibles} from "../../images/RiskProfiling/assets_icons/other_collectibles.svg";
import {ReactComponent as classic_vehicle} from "../../images/RiskProfiling/assets_icons/classic_vehicle.svg";
import {ReactComponent as yacht} from "../../images/RiskProfiling/assets_icons/yacht.svg";
import {ReactComponent as plane}from "../../images/RiskProfiling/assets_icons/plane.svg";
import {ReactComponent as liquor} from "../../images/RiskProfiling/assets_icons/liquor.svg";
import {ReactComponent as raw_collectibles} from "../../images/RiskProfiling/assets_icons/raw_collectibles.svg";
import {ReactComponent as other_assets} from "../../images/RiskProfiling/assets_icons/other_assets.svg";
import {ReactComponent as credit_card} from "../../images/RiskProfiling/assets_icons/credit_card.svg";
import {ReactComponent as mortgage} from "../../images/RiskProfiling/assets_icons/mortgage.svg";
import {ReactComponent as loan} from "../../images/RiskProfiling/assets_icons/loan.svg";
import {ReactComponent as other_liabilities} from "../../images/RiskProfiling/assets_icons/other_liabilities.svg";

export const FIELD_REQUIRED_MSG = 'Dies ist ein Pflichtfeld';
export const WRONG_DATE_FORMAT_MSG = 'Falsches Datumsformat.';
export const WEEKENDS_IS_NOT_AVAILABLE_MSG = "Datum kann nicht Wochenende sein";
export const DATE_MUST_BE_IN_PAST = "Das Datum muss in der Vergangenheit liegen.";
export const FIELD_DOES_NOT_FITT_MSG = 'Die Textgröße überschreitet das Limit. Bitte reduzieren Sie die Größe.';
export const LEGAL_AGE = 18;
export const OUTDATED_IDENTIFICATION_WARNING_MSG = 'Warnung: Kunden Identifikationsunterlagen abgelaufen';
export const NOT_COMPLETE_IDENTIFICATION_WARNING_MSG = 'Warnung: Die Ausweisdaten sind nicht vollständig bzw. fehlen';

export const CLEARING_ACCOUNT_NOT_ENOUGH_MONEY_WARNING_MSG = 'Warnung: Das Verrechnungskonto verfügt mit dem derzeitigen Stand nicht über ausreichendes Guthaben, um alle Orders auszuführen.';

export const SINGLE_INVESTMENT_HIGH_RISK_SCORE_WARNING = 'Sie können dem Kundenportfolio, mit entsprechender Begründung Produkte zur Diversifizierung beimischen, die als Einzelinvestment nicht dem Risikoprofil des Kunden entsprechen.  In diesen Fällen sind Sie verpflichtet, das Kundenportfolio und insbesondere die risikoreichen Positionen regelmäßig zu überprüfen und gegebenenfalls die Gewichtung anzupassen.';
export const SAVINGS_PLAN_HIGH_RISK_SCORE_WARNING = 'Wir weisen darauf hin, dass das gewählte Produkt eine höhere Risikoklasse, als die für den Kunden ermittelte hat. Im Rahmen eines Sparplanes können Sie dem Kunden dieses Produkt auf Grund der Regelmäßigkeit und Langfristigkeit der Einzahlungen trotzdem anbieten. In diesen Fällen sind Sie verpflichtet, dies entsprechend zu begründen.';
export const INVESTMENT_STRATEGY_SINGLE_INVESTMENT_HIGH_RISK_SCORE_WARNING = 'Wir weisen darauf hin, dass die Risikoklasse der ausgewählten Strategie höher als die Risikoeinstufung des Kunden ist. Sollte Ihr Kunde die Strategie dennoch erwerben wollen, sind Sie verpflichtet, ihn auf diesen Sachverhalt sowie auf alle mit dem Produkt einhergehenden Risiken hinzuweisen. Zudem muss der Kunde der höheren Risikoeinstufung zustimmen.';
export const INVESTMENT_STRATEGY_SINGLE_INVESTMENT_HIGH_EXPERIENCE_WARNING = 'Bitte beachten Sie, dass die ausgewählte Strategie umfassendere Kenntnisse und Erfahrungen erfordert, als Ihr Kunde besitzt. Sollte Ihr Kunde die Strategie dennoch erwerben wollen, müssen Sie ihn auf diesen Sachverhalt hinweisen und alle mit der Strategie verbundenen Risiken erläutern sowie dem Kunden Kenntnisse über die in der Strategie enthaltenen Finanzinstrumente vermitteln. Darüber hinaus ist die Zustimmung des Kunden zu diesem höheren Anforderungslevel erforderlich.';
export const INVESTMENT_STRATEGY_SINGLE_INVESTMENT_HIGH_RISK_SCORE_AND_EXPERIENCE_WARNING = 'Bitte beachten Sie, dass die ausgewählte Strategie eine höhere Risikoklasse aufweist als die Risikoeinstufung Ihres Kunden und umfassendere Kenntnisse und Erfahrungen erfordert, als Ihr Kunde besitzt. Sollte Ihr Kunde die Strategie dennoch erwerben wollen, müssen Sie ihn auf diesen Sachverhalt hinweisen und alle mit der Strategie verbundenen Risiken erläutern sowie dem Kunden Kenntnisse über die in der Strategie enthaltenen Finanzinstrumente vermitteln. Darüber hinaus ist die Zustimmung des Kunden zu diesem höheren Anforderungslevel erforderlich.';

export const CONFIRM_REMOVE_MSG = 'Begonnene Beratung sicher löschen?';

export const PAYMENT_PLANS_PORTFOLIO_ONLY_DELETE_PAYMENT_PLAN_MSG = <p className="no-margin">Sie möchten in einem ausschliesslichen Sparplandepot welches nur Positionen aus laufenden Sparplänen enthält eine Sparplaninstruktion entfernen. Bitte beachten Sie, dass in diesem Falle, vorausgesetzt sie handeln unter ‘Anlageberatung’ oder der Kunde hat die Option ‘Ständige Geeignetheitsprüfung’ gewählt, das Depot zukünftig der Risikoregel für Portfolios unterworfen wird, da später nicht mehr nachvollzogen werden kann, ob die resultierende Position aus einem Sparplan oder einer Einmaltransaktion stammt. D.h. Sie müssen die Bestände risikoadjustieren, wenn das durchschnittliche Portfoliorisiko grösser als der aktuell zulässige Kundenrisikogrenzwert (Kundenrisikoklasse + 0,49) werden sollte.< br/>Es steht ihnen aber frei jetzt gleichzeitig die resultierende Position aus dem zu löschenden Sparplan zu veräussern, um Anwendung dieser Risikoregel zu umgehen.< br/>Diese Regel gilt nicht für Portfolios, welche ausschliesslich Positionen aus laufenden Sparplänen beinhalten.</p>

export const PAYMENT_PLANS_PORTFOLIO_ONLY_DELETE_PAYMENT_PLAN_DASHBOARD_MSG = <p className="no-margin">Dieser Hinweistext ist nur relevant falls Sie unter ‘Anlageberatung’ eine Änderungen an diesem Depot vornehmen wollen. {PAYMENT_PLANS_PORTFOLIO_ONLY_DELETE_PAYMENT_PLAN_MSG}</p>

export const PAYMENT_PLANS_PORTFOLIO_ONLY_ADD_NEW_POSITION_MSG = <p className="no-margin">Sie möchten zu einem ausschliesslichen Sparplandepot eine Einmaltransaktion hinzufügen. Bitte beachten Sie, dass in diesem Falle, vorausgesetzt sie handeln unter ‘Anlageberatung’ oder der Kunde hat die Option ‘Ständige Geeignetheitsprüfung’ gewählt, das Depot der Risikoregel für Portfolios unterworfen wird. D.h. Sie müssen die Bestände risikoadjustieren, wenn das durchschnittliche Portfoliorisiko grösser als der aktuell zulässige Kundenrisikogrenzwert (Kundenrisikoklasse + 0,49) werden sollte.<br />Diese Regel gilt nicht für Portfolios, welche ausschliesslich Positionen aus laufenden Sparplänen beinhalten.</p>

export const OPTION_IS_NOT_AVAILABLE_FOR_DEPOT_MSG = 'Die gewählte Depotbank unterstützt diese Option aktuell nicht.'

export const SNACKBAR_DURATION_IN_SEC = 30;

export const QUESTION_TYPE = {
  GROUP: 'group',
  RADIO: 'radio',
  INPUT: 'input',
  HIDDEN: 'hidden',
  SELECT: 'select',
  CHECKBOX: 'checkbox',
  RADIO_SELECT: 'radio_select',
  CALENDAR: 'calendar',
  LIST_SELECT: 'list_select',
  SLIDER: 'slider',
  ASSETS: 'assets',
  CHART_SELECT: 'chart_select',
  DATE_SELECT: 'date',
  REFERENCE_ACCOUNTS: 'reference-accounts',
  TIME_SELECT: 'time',
  COUNTRY_SELECT: 'country_select',
  LEGITIMATION_DOCUMENT: 'legitimation_document',
  INFO_LIST: 'info_list',
  CHECKBOX_LIST: 'checkbox_list',
  TOGGLE: 'toggle',
};

export const STEP_TYPE = {
  INSTRUMENTS: 'instruments',
  ASSETS: 'assets',
  DYNAMIC: 'dynamic',
  FINAL: 'final',
  CONGRATS: 'congrats',
  INVESTMENT_INTERMEDIARY: 'investment_intermediary'
};

export const ASSETS_STEP_STATE = {
  MAIN: 'main',
  LIST: 'list',
  ADD_FORM: 'add_form',
  EDIT_FORM: 'edit_form',
};

export const INSTRUMENTS_STEP_STATE = {
  MAIN: 'main',
  LIST: 'list',
  ADD_FORM: 'add_form',
};

export const ASSET_DATA_UID = 'M1';
export const LIABILITY_CHECKBOX_UID = 'N0';
export const LIABILITY_DATA_UID = 'N1';

export const ASSET_DATA = {
  name: 'Vermögenswert manuell hinzufügen',
  items: [
    {id: 1, icon: cash_icon, name: 'Bargeld'},
    {id: 2, icon: commodities, name: 'Investments & Depots'},
    {id: 3, icon: company_shares, name: 'Beteiligung/Firmenanteile'},
    {id: 4, icon: estates, name: 'Eigene Wohnung/Haus'},
    {id: 5, icon: real_estate, name: 'Immobilien'},
    {id: 6, icon: life_insurance, name: 'Lebensversicherung'},
    {id: 7, icon: private_company, name: 'Geschlossene Fonds'},
    {id: 8, icon: receivable_loans, name: 'Darlehensforderung'},
    {id: 9, icon: real_estate_2, name: 'Grundbesitz'},
    {id: 10, icon: savings, name: 'Ersparnisse'},
    {id: 11, icon: gold, name: 'Edelmetalle'},
    {id: 12, icon: jewellery, name: 'Schmuck/Uhren'},
    {id: 13, icon: valuable_items, name: 'Kunstobjekte'},
    {id: 14, icon: antiques, name: 'Antiquitäten'},
    {id: 15, icon: other_collectibles, name: 'Andere Sammlerstücke'},
    {id: 16, icon: classic_vehicle, name: 'Autos & Oldtimer'},
    {id: 17, icon: yacht, name: 'Booten und Yachten'},
    {id: 18, icon: plane, name: 'Flugzeug'},
    {id: 19, icon: liquor, name: 'Weine/Spirituosen'},
    {id: 20, icon: raw_collectibles, name: 'Rohstoffe'},
    {id: 21, icon: other_assets, name: 'Andere Vermögenswerte'},
  ]
};

export const LIABILITY_DATA = {
  name: 'Verbindlichkeit manuell hinzufügen',
  items: [
    {id: 1, icon: credit_card, name: 'Kreditkarten'},
    {id: 2, icon: mortgage, name: 'Hypothek'},
    {id: 3, icon: loan, name: 'Darlehen'},
    {id: 4, icon: other_liabilities, name: 'Andere Verbindlichkeiten'},
  ]
};

export const VISIBLE_ASSETS = 6;

export const radioYesUID = 'radio_yes';
export const radioNoUID = 'radio_no';
export const radioYesValue = radioYesUID;
export const radioNoValue = radioNoUID;
export const radioOptions = [
  {id: 1, value: radioYesValue, label: 'Ja'},
  {id: 2, value: radioNoValue, label: 'Nein'}
];

export const defaultRadioValue = radioNoValue;
export const radioSelectNoKnowledgeValue = '1';
export const radioSelectNoExperienceValue = 'order_no_experience';

export const LEGITIMATION_ID_CARD_UID = '1';
export const LEGITIMATION_MAPPING = {
  "Personalausweis": LEGITIMATION_ID_CARD_UID,
  "Reisepass": "2"
};
export const LEGITIMATION_MINOR_MAPPING = {
  ...LEGITIMATION_MAPPING,
  "Geburtsurkunde": "4"
};

export const LEGITIMATION_TYPES = Object.keys(LEGITIMATION_MAPPING).map(title => {
  return {"id": LEGITIMATION_MAPPING[title], "uid": LEGITIMATION_MAPPING[title], "text": title}
});

export const LEGITIMATION_MINOR_TYPES = Object.keys(LEGITIMATION_MINOR_MAPPING).map(title => {
  return {"id": LEGITIMATION_MINOR_MAPPING[title], "uid": LEGITIMATION_MINOR_MAPPING[title], "text": title}
});

const getMemberConfirmationGroups = (memberIndex) => [
  {
    uid: "A-general",
    question_text: '',
    info_text: '',
    questionUIDs: [`A20${memberIndex}`, `A40${memberIndex}`, `A50${memberIndex}`, `A60${memberIndex}`]
  },
  {
    uid: "A-address",
    question_text: 'Adresse',
    info_text: '',
    questionUIDs: [`A70${memberIndex}`, `A80${memberIndex}`, `A90${memberIndex}`, `A10${memberIndex}`, `A110${memberIndex}`]
  },
  {
    uid: "A-birth",
    question_text: "Ihre Geburtsdaten",
    questionUIDs: [`C10${memberIndex}`, `C20${memberIndex}`],
  },
  {
    uid: "A-contacts",
    question_text: 'Kontaktdaten',
    info_text: '',
    questionUIDs: [`B10${memberIndex}`, `B20${memberIndex}`]
  },
];

export const stepGroups = {
  'A1': [
    {
      uid: "A-general",
      question_text: '',
      info_text: '',
      questionUIDs: ['A2', 'A4', 'A5', 'A6', 'A50', 'A51', 'A52', 'A53', 'A54']
    },
    {
      uid: "A-address",
      question_text: 'Adresse',
      info_text: '',
      questionUIDs: ['A7', 'A8', 'A9', 'A10', 'A11']
    },
    {
      uid: "A-birth",
      question_text: "Ihre Geburtsdaten",
      questionUIDs: ['C1', 'C2'],
    },
    {
      uid: "A-contacts",
      question_text: 'Kontaktdaten',
      info_text: '',
      questionUIDs: ['B1', 'B2']
    },
  ],
  'A101': getMemberConfirmationGroups(1),
  'A102': getMemberConfirmationGroups(2)
};

export const SET_PRODUCT_SELECTION_INVESTMENT_RECOMENDATION = 'SET_PRODUCT_SELECTION_INVESTMENT_RECOMENDATION';

export const SET_PRODUCT_SELECTION_SRRI = 'SET_PRODUCT_SELECTION_SRRI';

export const CountryISOMapping = {
  AFG: "AF",
  ALA: "AX",
  ALB: "AL",
  DZA: "DZ",
  ASM: "AS",
  AND: "AD",
  AGO: "AO",
  AIA: "AI",
  ATA: "AQ",
  ATG: "AG",
  ARG: "AR",
  ARM: "AM",
  ABW: "AW",
  AUS: "AU",
  AUT: "AT",
  AZE: "AZ",
  BHS: "BS",
  BHR: "BH",
  BGD: "BD",
  BRB: "BB",
  BLR: "BY",
  BEL: "BE",
  BLZ: "BZ",
  BEN: "BJ",
  BMU: "BM",
  BTN: "BT",
  BOL: "BO",
  BIH: "BA",
  BWA: "BW",
  BVT: "BV",
  BRA: "BR",
  VGB: "VG",
  IOT: "IO",
  BRN: "BN",
  BGR: "BG",
  BFA: "BF",
  BDI: "BI",
  KHM: "KH",
  CMR: "CM",
  CAN: "CA",
  CPV: "CV",
  CYM: "KY",
  CAF: "CF",
  TCD: "TD",
  CHL: "CL",
  CHN: "CN",
  HKG: "HK",
  MAC: "MO",
  CXR: "CX",
  CCK: "CC",
  COL: "CO",
  COM: "KM",
  COG: "CG",
  COD: "CD",
  COK: "CK",
  CRI: "CR",
  CIV: "CI",
  HRV: "HR",
  CUB: "CU",
  CYP: "CY",
  CZE: "CZ",
  DNK: "DK",
  DJI: "DJ",
  DMA: "DM",
  DOM: "DO",
  ECU: "EC",
  EGY: "EG",
  SLV: "SV",
  GNQ: "GQ",
  ERI: "ER",
  EST: "EE",
  ETH: "ET",
  FLK: "FK",
  FRO: "FO",
  FJI: "FJ",
  FIN: "FI",
  FRA: "FR",
  GUF: "GF",
  PYF: "PF",
  ATF: "TF",
  GAB: "GA",
  GMB: "GM",
  GEO: "GE",
  DEU: "DE",
  GHA: "GH",
  GIB: "GI",
  GRC: "GR",
  GRL: "GL",
  GRD: "GD",
  GLP: "GP",
  GUM: "GU",
  GTM: "GT",
  GGY: "GG",
  GIN: "GN",
  GNB: "GW",
  GUY: "GY",
  HTI: "HT",
  HMD: "HM",
  VAT: "VA",
  HND: "HN",
  HUN: "HU",
  ISL: "IS",
  IND: "IN",
  IDN: "ID",
  IRN: "IR",
  IRQ: "IQ",
  IRL: "IE",
  IMN: "IM",
  ISR: "IL",
  ITA: "IT",
  JAM: "JM",
  JPN: "JP",
  JEY: "JE",
  JOR: "JO",
  KAZ: "KZ",
  KEN: "KE",
  KIR: "KI",
  PRK: "KP",
  KOR: "KR",
  KWT: "KW",
  KGZ: "KG",
  LAO: "LA",
  LVA: "LV",
  LBN: "LB",
  LSO: "LS",
  LBR: "LR",
  LBY: "LY",
  LIE: "LI",
  LTU: "LT",
  LUX: "LU",
  MKD: "MK",
  MDG: "MG",
  MWI: "MW",
  MYS: "MY",
  MDV: "MV",
  MLI: "ML",
  MLT: "MT",
  MHL: "MH",
  MTQ: "MQ",
  MRT: "MR",
  MUS: "MU",
  MYT: "YT",
  MEX: "MX",
  FSM: "FM",
  MDA: "MD",
  MCO: "MC",
  MNG: "MN",
  MNE: "ME",
  MSR: "MS",
  MAR: "MA",
  MOZ: "MZ",
  MMR: "MM",
  NAM: "NA",
  NRU: "NR",
  NPL: "NP",
  NLD: "NL",
  ANT: "AN",
  NCL: "NC",
  NZL: "NZ",
  NIC: "NI",
  NER: "NE",
  NGA: "NG",
  NIU: "NU",
  NFK: "NF",
  MNP: "MP",
  NOR: "NO",
  OMN: "OM",
  PAK: "PK",
  PLW: "PW",
  PSE: "PS",
  PAN: "PA",
  PNG: "PG",
  PRY: "PY",
  PER: "PE",
  PHL: "PH",
  PCN: "PN",
  POL: "PL",
  PRT: "PT",
  PRI: "PR",
  QAT: "QA",
  REU: "RE",
  ROU: "RO",
  RUS: "RU",
  RWA: "RW",
  BLM: "BL",
  SHN: "SH",
  KNA: "KN",
  LCA: "LC",
  MAF: "MF",
  SPM: "PM",
  VCT: "VC",
  WSM: "WS",
  SMR: "SM",
  STP: "ST",
  SAU: "SA",
  SEN: "SN",
  SRB: "RS",
  SYC: "SC",
  SLE: "SL",
  SGP: "SG",
  SVK: "SK",
  SVN: "SI",
  SLB: "SB",
  SOM: "SO",
  ZAF: "ZA",
  SGS: "GS",
  SSD: "SS",
  ESP: "ES",
  LKA: "LK",
  SDN: "SD",
  SUR: "SR",
  SJM: "SJ",
  SWZ: "SZ",
  SWE: "SE",
  CHE: "CH",
  SYR: "SY",
  TWN: "TW",
  TJK: "TJ",
  TZA: "TZ",
  THA: "TH",
  TLS: "TL",
  TGO: "TG",
  TKL: "TK",
  TON: "TO",
  TTO: "TT",
  TUN: "TN",
  TUR: "TR",
  TKM: "TM",
  TCA: "TC",
  TUV: "TV",
  UGA: "UG",
  UKR: "UA",
  ARE: "AE",
  GBR: "GB",
  USA: "US",
  UMI: "UM",
  URY: "UY",
  UZB: "UZ",
  VUT: "VU",
  VEN: "VE",
  VNM: "VN",
  VIR: "VI",
  WLF: "WF",
  ESH: "EH",
  YEM: "YE",
  ZMB: "ZM",
  ZWE: "ZW",
  XKX: "XK"
};

export const DefaultCountry = "DE";

export const OBJECT_TYPES = {
  ONBOARDING: 1,
  TRADING: 2,
  SAVINGS_PLAN: 3,
  PAYOUT_PLAN: 4,
  SWITCH_PLAN: 5,
  COMBINED: 6
};

export const USER_CONFIRMATION_STEP_IDENTIFIER = 'A1';
export const TAX_STEP_ID = "taxable";
export const LEGITIMATION_STEP_ID = "Identifikationsunterlagen";
export const EMPLOYER_STEP_ID = "employerInformation";
export const REF_ACCOUNT_STEP_ID = "Referenzkonto";
export const CONGRATS_STEP_ID = "congrats-step";

export const COUPLE_MEMBERS_CONFIRMATION_STEP_IDENTIFIERS = ['A101', 'A102'];

export const LEGITIMATION_DOCUMENTS_MEMBERS_STEP_IDENTIFIERS = ['Identifikationsunterlagen01', 'Identifikationsunterlagen02'];

export const LEGITIMATION_DOCUMENTS_STEP_IDENTIFIERS = [
  'Identifikationsunterlagen', ...LEGITIMATION_DOCUMENTS_MEMBERS_STEP_IDENTIFIERS];

export const COUPLE_MEMBERS_OTHER_FIELDS = [
  ...COUPLE_MEMBERS_CONFIRMATION_STEP_IDENTIFIERS,
  ...LEGITIMATION_DOCUMENTS_MEMBERS_STEP_IDENTIFIERS,
  'taxable01', 'taxable02',
];

export const COUPLE_MEMBERS_DISABLED_FIELDS_IDENTIFIERS = ['A51', 'A52', 'A53', 'A54'];

export const USER_CONFIRMATION_STEP_IDENTIFIERS = [
  USER_CONFIRMATION_STEP_IDENTIFIER,
  ...COUPLE_MEMBERS_CONFIRMATION_STEP_IDENTIFIERS];

// warning to display when in CRM customer type is minor, but our app detecta that he is old enough to be single client
export const WRONG_TYPE_MINOR_IN_CRM_WARNING = 'Der vormals minderjährige Depotinhaber ist seit dem letzten Zugriff volljährig geworden.' +
  ' Der Kundentyp im CRM lautet aber weiterhin auf ‘Minderjährig’. Bitte korrigieren Sie dieses im CRM. \n' +
  'Unabhängig davon kann der gestartete Geschäftprozess aber hier weitergeführt werden. \n' +
  'Bitte beachten Sie auch den nunmehr volljährigen Depotinhaber gegenüber der/ den Depotbank(en) zu legitimieren,' +
  ' bevor Sie die erste Order für den Depotinhaber in Auftrag geben.'

export const CUSTOMER_TYPES = {
    SINGLE_PERSON: '0',
    COUPLE: '1',
    MINOR: '2',
    COMPANY: '3'
};

export const PROCESS_TYPE = {
  ONBOARDING: 0,
  TRADING: 1
};

export const CLEARING_ACCOUNT_QUESTION_UID = 'clearing_account';
export const CLEARING_ACCOUNT_OPTION_QUESTION_UID = 'clearing_account_option';
export const CLEARING_ACCOUNT_BALANCING_QUESTION_UID = 'clearing_account_balancing';

export const SECURITY_DOCUMENT_HANDED_STEP_UIDS = ['K2', 'A3'];

export const INSTRUMENT_SOURCE_TYPE = {
  SEARCH: 'search',
  FAVOURITE_LIST: 'favourite_list',
  MODEL_PORTFOLIO: 'model_portfolio',
  MUSTER_DEPOT: 'muster_depot',
  INVESTMENT_STRATEGY: 'investment_strategy',
  PORTFOLIO_BUILDER: 'portfolio_builder'
};

export const MAX_RISK_THRESHOLD = 0.49;

export const SINGLE_CUSTOMER_PERMANENT_SUITABILITY_CHECK_DISABLED_TEXT = 'Mit dem Kunden wird keine dauerhafte Geeignetheitsprüfung vereinbart.';
export const SINGLE_CUSTOMER_PERMANENT_SUITABILITY_CHECK_ENABLED_TEXT = 'Mit dem Kunden wird eine dauerhafte Geeignetheitsprüfung vereinbart.';
export const COUPLE_PERMANENT_SUITABILITY_CHECK_DISABLED_TEXT = 'Mit den Kunden wird keine dauerhafte Geeignetheitsprüfung vereinbart.';
export const COUPLE_PERMANENT_SUITABILITY_CHECK_ENABLED_TEXT = 'Mit den Kunden wird eine dauerhafte Geeignetheitsprüfung vereinbart.';

export const RISK_SCORE_SINGLE_INVESTMENT_EXPLANATION_MODAL_UID = 'risk_score_single_investment_explanation';
export const RISK_SCORE_SAVINGS_PLAN_EXPLANATION_MODAL_UID = 'risk_score_savings_plan_explanation';
export const RISK_SCORE_INVESTMENT_STRATEGY_SCORE_EXPLANATION_MODAL_UID = 'risk_score_investment_strategy_explanation';
export const RISK_SCORE_INVESTMENT_STRATEGY_EXPERIENCE_EXPLANATION_MODAL_UID = 'rs_is_experience_explanation';
export const RISK_SCORE_INVESTMENT_STRATEGY_EXPERIENCE_AND_SCORE_EXPLANATION_MODAL_UID = 'rs_is_experience_and_score_explanation';

export const VALID_UNTIL_TODAY_VALUE = 'till_today';

export const kundendatenStep = [USER_CONFIRMATION_STEP_IDENTIFIER, USER_CONFIRMATION_STEP_IDENTIFIER, TAX_STEP_ID,
  LEGITIMATION_STEP_ID, EMPLOYER_STEP_ID, REF_ACCOUNT_STEP_ID, CONGRATS_STEP_ID];

export const PB_KNOWLEDGE_MAPPING = {
  // ALTERNATIVE_FUNDS: 'H5', // Komplexe Finanzinstrumente (bspw. AIF)
  FIXED_INCOME_FUNDS: 'H2', // Schuldverschreibungen
  COMMODITIES_FUNDS: 'H3', // Offene Investmentfonds
  EQUITIES_FUNDS: 'H3', // Offene Investmentfonds
  MULTI_ASSET_FUNDS: 'H3', // Offene Investmentfonds
  MONEY_MARKET_FUNDS: 'H1', // Geldmarktinstrumente
  REAL_ESTATE_FUNDS: 'H5', // Komplexe Finanzinstrumente (bspw. AIF)
  SINGLE_STOCKS: 'H4', // Aktien
};